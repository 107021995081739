import { Component, OnInit } from '@angular/core';
import { AccountService, AlertService } from './_services';
import { InputRefNoDialog } from '@app/_components/home/home.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PublicClientApplication } from '@azure/msal-browser';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Allianz Portal';
  isIframe = false;
  inputDialogRef!: MatDialogRef<InputRefNoDialog>;

  constructor(
    public accountService: AccountService, public dialog: MatDialog, private router: Router, private alertService: AlertService
  ) { }

  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.accountService.startup();
  }

  public async openSearchDialog(isByRefNo: boolean) {
    this.inputDialogRef = this.dialog.open(InputRefNoDialog, {
      minWidth: '400px',
      maxWidth: '1000px',
      data: { isByRefNo: isByRefNo }
    });

    /*this.inputDialogRef.afterClosed().subscribe(result => {
      //console.log('result = ' + result);
      if (result == '' || result == undefined) {
        this.inputDialogRef.close('');
        this.alertService.error('Invalid Claim. This claim either could be archived or not yet registered. Please contact Mk3 IT-Helpdesk');
      }
      else
        this.router.navigate(['./case-details/' + result]);
    },
      error => {
        console.log('error = ' + error);
        alert(error);
      }
    )*/
  }
}
