<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="portal-header">
    <h6 class="header-text">
      <table style="width:100%">
        <tr>
          <td style="width:33%;text-align: left;" *ngIf="vClaimsAll && vClaimsAll.caseNo">&nbsp;Insurance Claim #
            {{vClaimsAll.caseNo}}</td>
          <td style="width:34%">
            <!-- <ng-container *ngIf="vClaimsAll && vClaimsAll.insuredFullName; else elseNoFullName">
              {{vClaimsAll.insuredFullName}}
            </ng-container>
            <ng-template #elseNoFullName>
              {{vClaimsAll.lastName}}
            </ng-template> -->
            Insured: {{vClaimsAll.lastName}}
          </td>
          <td style="width:33%;text-align: right;"
            *ngIf="vClaimsAll && vClaimsAll.statusClaimType && vClaimsAll.originalStatus">Claim
            Status: {{vClaimsAll.statusClaimType.trim()}}, {{vClaimsAll.originalStatus.trim()}}&nbsp;</td>
        </tr>
      </table>
    </h6>
  </div>

  <div class="content">
    <div fxLayout="row wrap" fxLayoutGap="12px grid" fxLayoutAlign="stretch">

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Policy Details</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Customer</th>
                <td>
                  <ng-container *ngIf="vClaimsAll && vClaimsAll.insuredFullName; else elseNoFullName">
                    {{vClaimsAll.insuredFullName}}
                  </ng-container>
                  <ng-template #elseNoFullName>
                    {{vClaimsAll.lastName}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Policy Number</th>
                <td *ngIf="vClaimsAll && vClaimsAll.policyNumber">{{vClaimsAll.policyNumber}}</td>
              </tr>
              <tr>
                <th>Risk Address</th>
                <td><a href="https://www.google.com.au/maps/place/{{vClaimsAll.situationStreet.replace('/', '%2F')}} {{vClaimsAll.situationSuburb.replace('/', '%2F')}} {{vClaimsAll.state}} {{vClaimsAll.postCode}}"
                  target="_blank">
                  {{vClaimsAll.situationStreet ? vClaimsAll.situationStreet : ''}}, {{vClaimsAll.situationSuburb}},
                  {{vClaimsAll.state}} {{vClaimsAll.postCode}}
                  </a>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Building Sum Insured</th>
                <td *ngIf="vClaimsAll && vClaimsAll.buildingSumInsured">${{vClaimsAll.buildingSumInsured}}</td>
              </tr>
              <tr>
                <th>Contents Sum Insured</th>
                <td *ngIf="vClaimsAll && vClaimsAll.contentsSumInsured">${{vClaimsAll.contentsSumInsured}}</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Building Excess</th>
                <td *ngIf="vClaimsAll && vClaimsAll.excessBuilding">${{vClaimsAll.excessBuilding}}</td>
              </tr>
              <tr>
                <th>Contents Excess</th>
                <td *ngIf="vClaimsAll && vClaimsAll.excessContents">${{vClaimsAll.excessContents}}</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Customer Contact Details</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Primary Contact</th>
                <td *ngIf="claimContact && claimContact[0] && claimContact[0].name">{{claimContact[0].name}}
                  <ng-container *ngIf="claimContact && claimContact[0] && claimContact[0].claimContactRole">
                    ({{claimContact[0].claimContactRole}})
                  </ng-container>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Primary Contact Phone Number</th>
                <td *ngIf="claimContact && claimContact[0] && claimContact[0].phone">
                  <a href="tel:{{claimContact[0].phone}}">{{claimContact[0].phone}}</a>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Primary Contact Email</th>
                <td *ngIf="claimContact && claimContact[0] && claimContact[0].emailAddress">
                  <a href="mailto:{{claimContact[0].emailAddress}}">{{claimContact[0].emailAddress}}</a>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Contact 2</th>
                <td *ngIf="claimContact && claimContact[1] && claimContact[1].name">{{claimContact[1].name}}
                  <ng-container *ngIf="claimContact && claimContact[1] && claimContact[1].claimContactRole">
                    ({{claimContact[1].claimContactRole}})
                  </ng-container>
                </td>
              </tr>
              <tr>
                <th>Contact 2 Phone Number</th>
                <td *ngIf="claimContact && claimContact[1] && claimContact[1].phone">
                  <a href="tel:{{claimContact[1].phone}}">{{claimContact[1].phone}}</a>
                </td>
              </tr>
              <tr>
                <th>Contact 2 Email</th>
                <td *ngIf="claimContact && claimContact[1] && claimContact[1].emailAddress">
                  <a href="mailto:{{claimContact[1].emailAddress}}">{{claimContact[1].emailAddress}}</a>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Contact 3</th>
                <td *ngIf="claimContact && claimContact[2] && claimContact[2].name">{{claimContact[2].name}}
                  <ng-container *ngIf="claimContact && claimContact[2] && claimContact[2].claimContactRole">
                    ({{claimContact[2].claimContactRole}})
                  </ng-container>
                </td>
              </tr>
              <tr>
                <th>Contact 3 Phone Number</th>
                <td *ngIf="claimContact && claimContact[2] && claimContact[2].phone">
                  <a href="tel:{{claimContact[2].phone}}">{{claimContact[2].phone}}</a>
                </td>
              </tr>
              <tr>
                <th>Contact 3 Email</th>
                <td *ngIf="claimContact && claimContact[2] && claimContact[2].emailAddress">
                  <a href="mailto:{{claimContact[2].emailAddress}}">{{claimContact[2].emailAddress}}</a>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Claim Details</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Claim Number</th>
                <td *ngIf="vClaimsAll && vClaimsAll.caseNo">{{vClaimsAll.caseNo}}</td>
              </tr>
              <tr>
                <th>Mk3 Received Date</th>
                <td *ngIf="vClaimsAll && vClaimsAll.receivedDate">{{vClaimsAll.receivedDate | date: 'dd/MM/yyyy'}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Date of Loss</th>
                <td *ngIf="vClaimsAll && vClaimsAll.dateOfLoss">{{vClaimsAll.dateOfLoss | date: 'dd/MM/yyyy'}}</td>
              </tr>
              <tr>
                <th>Cause of Damage</th>
                <td *ngIf="vClaimsAll && vClaimsAll.peril">{{vClaimsAll.peril}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Case Manager</th>
                <td *ngIf="vClaimsAll && vClaimsAll.insurerContactName">{{vClaimsAll.insurerContactName}}</td>
              </tr>
              <tr>
                <th>CAT Code</th>
                <td *ngIf="vClaimsAll && vClaimsAll.catastrophe">{{vClaimsAll.catastrophe}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>
            <table style="width:100%">
              <tr>
                <td style="width:33%">Claim Assessment</td>
                <td style="width:33%">Progress Status</td>
                <td style="width:33%">Claim Reserve</td>
              </tr>
            </table>
          </h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Mk3 Reference</th>
                <td *ngIf="vClaimsAll && vClaimsAll.refNo">{{vClaimsAll.refNo}}</td>
              </tr>
              <tr>
                <th>Mk3 Assessor</th>
                <td *ngIf="vClaimsAll && vClaimsAll.consultant">{{vClaimsAll.consultant}}</td>
              </tr>
              <tr>
                <th>Last Assessment Date</th>
                <td>                  
                  <ng-container *ngIf="vClaimsAll.inspectionDate === null">
                </ng-container>
                <ng-container *ngIf="vClaimsAll.inspectionDate !== null && functionsService.getTimeValue(vClaimsAll.inspectionDate) === functionsService.getTime5555(); else elseValidInspDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidInspDate>
                    {{vClaimsAll.inspectionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Assessment Type</th>
                <td *ngIf="vClaimsAll && vClaimsAll.assessmentType">{{vClaimsAll.assessmentType}}</td>
              </tr>
              <tr>
                <th style="width:50%">First Report Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.sentReportDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.sentReportDate !== null && functionsService.getTimeValue(vClaimsAll.sentReportDate) === functionsService.getTime5555(); else elseValidReportSentDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidReportSentDate>
                    {{ vClaimsAll.sentReportDate | date: 'dd/MM/yyyy' }}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Claim Acceptance</th>
                <td *ngIf="vClaimsAll && vClaimsAll.acceptance">{{vClaimsAll.acceptance}}</td>
              </tr>
              <tr>
                <th style="width:50%">Claim Recommendation</th>
                <td *ngIf="vClaimsAll && vClaimsAll.claimRecommendation">{{vClaimsAll.claimRecommendation}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th>Emergency Repair</th>
                <td>{{vClaimsAll.emergencyRepairType}}</td>
              </tr>
              <tr>
                <th>Signed SOW received Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.dateScopeOfWorkSignedReceived === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.dateScopeOfWorkSignedReceived !== null && functionsService.getTimeValue(vClaimsAll.dateScopeOfWorkSignedReceived) === functionsService.getTime5555(); else elseValidSOWReceivedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidSOWReceivedDate>
                    {{vClaimsAll.dateScopeOfWorkSignedReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Signed Contract Received</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.signedContractReceived === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.signedContractReceived !== null && functionsService.getTimeValue(vClaimsAll.signedContractReceived) === functionsService.getTime5555(); else elseValidSigConRecDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidSigConRecDate>
                    {{vClaimsAll.signedContractReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Excess Received Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.excessDateReceived === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.excessDateReceived !== null && functionsService.getTimeValue(vClaimsAll.excessDateReceived) === functionsService.getTime5555(); else elseValidXSReceivedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidXSReceivedDate>
                    {{vClaimsAll.excessDateReceived | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Estimated Completion Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.estimatedCompletionDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.estimatedCompletionDate !== null && functionsService.getTimeValue(vClaimsAll.estimatedCompletionDate) === functionsService.getTime5555(); else elseValidECD">
                    N/A
                  </ng-container>
                  <ng-template #elseValidECD>
                    {{vClaimsAll.estimatedCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Contents Component Completed Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.contentsCompletionDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.contentsCompletionDate !== null && functionsService.getTimeValue(vClaimsAll.contentsCompletionDate) === functionsService.getTime5555(); else elseValidContentsWorksCompletedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidContentsWorksCompletedDate>
                    {{vClaimsAll.contentsCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th style="width:50%">Building Component Completed Date</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.buildingCompletionDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.buildingCompletionDate !== null && functionsService.getTimeValue(vClaimsAll.buildingCompletionDate) === functionsService.getTime5555(); else elseValidBuildingWorksCompletedDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidBuildingWorksCompletedDate>
                    {{vClaimsAll.buildingCompletionDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th>Initial Site Building Reserve</th>
                <td *ngIf="vClaimsAll.buildingPriceRange != null">{{vClaimsAll.buildingPriceRange}}</td>
              </tr>
              <tr>
                <th>Section 1: Building Reserve</th>
                <td *ngIf="vClaimsAll.buildingEstimate === null && vClaimsAll.restorationEstimate === null"></td>
                <td *ngIf="vClaimsAll.buildingEstimate !== null || vClaimsAll.restorationEstimate !== null">
                  ${{(vClaimsAll.buildingEstimate ? vClaimsAll.buildingEstimate : 0) + (vClaimsAll.restorationEstimate ?
                  vClaimsAll.restorationEstimate : 0) | number :
                  '1.2-2'}}</td>
              </tr>
              <tr>
                <th>Section 2: Contents Reserve</th>
                <td *ngIf="vClaimsAll.contentsEstimate != null">${{vClaimsAll.contentsEstimate | number :
                  '1.2-2'}}</td>
              </tr>
              <tr>
                <th style="width:50%">Section 4: Loss of Rent</th>
                <td *ngIf="vClaimsAll.lorAmountSettled != null">${{vClaimsAll.lorAmountSettled | number :
                  '1.2-2'}}</td>
              </tr>
              <tr>
                <th style="width:50%">Emergency Make Safe</th>
                <td *ngIf="vClaimsAll.emergencyRepairEst != null">${{vClaimsAll.emergencyRepairEst | number :
                  '1.2-2'}}</td>
              </tr>
              <tr>
                <th style="width:50%">Mk3 Fees</th>
                <td *ngIf="vClaimsAll.mk3Fee != null">${{vClaimsAll.mk3Fee | number : '1.2-2'}}</td>
              </tr>
              <tr>
                <th style="width:50%">Total Reserve</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.buildingEstimate === null && 
                                        vClaimsAll.restorationEstimate === null &&
                                        vClaimsAll.contentsEstimate === null &&
                                        vClaimsAll.lorAmountSettled === null &&
                                        vClaimsAll.emergencyRepairEst === null &&
                                        vClaimsAll.mk3Fee === null; else elseTotalNotNull">
                  </ng-container>
                  <ng-template #elseTotalNotNull>
                    ${{(vClaimsAll.buildingEstimate ? vClaimsAll.buildingEstimate : 0) + 
                      (vClaimsAll.restorationEstimate ? vClaimsAll.restorationEstimate : 0) +
                      (vClaimsAll.contentsEstimate ? vClaimsAll.contentsEstimate : 0) + 
                      (vClaimsAll.lorAmountSettled ? vClaimsAll.lorAmountSettled : 0) + 
                      (vClaimsAll.emergencyRepairEst ? vClaimsAll.emergencyRepairEst : 0) +
                      (vClaimsAll.mk3Fee ? vClaimsAll.mk3Fee : 0) | number : '1.2-2'}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Customer Contact Dates</h6>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Date First Contacted</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.firstContactDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.firstContactDate !== null && functionsService.getTimeValue(vClaimsAll.firstContactDate) === functionsService.getTime5555(); else elseValidFirstContactDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidFirstContactDate>
                    {{vClaimsAll.firstContactDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Date Last Contacted</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.lastContactDate === null">
                  </ng-container>
                  <ng-container *ngIf="vClaimsAll.lastContactDate !== null && functionsService.getTimeValue(vClaimsAll.lastContactDate) === functionsService.getTime5555(); else elseValidLastContactDate">
                    N/A
                  </ng-container>
                  <ng-template #elseValidLastContactDate>
                    {{vClaimsAll.lastContactDate | date: 'dd/MM/yyyy'}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th style="width:50%">Hold Customer Contact</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.isHoldContact; else elseNo">Yes
                  </ng-container>
                  <ng-template #elseNo>
                    No
                  </ng-template>
                </td>
              </tr>
              <tr>
                <th>Days Since Last Contact</th>
                <td>
                  <ng-container *ngIf="vClaimsAll.statusClaimTypeID != 1; else elseValidContactDays">
                    N/A
                  </ng-container>
                  <ng-template #elseValidContactDays>
                    {{vClaimsAll.contactDays}}
                  </ng-template>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div fxFlex="33%" fxFlex.xs="100%" fxFlex.sm="50%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <td>&nbsp;</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card class="mat-card-heading">
          <h6>Claim Description and Instruction</h6>
        </mat-card>
      </div>

      <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
        <mat-card>
          <mat-card-content>
            <table style="width:100%">
              <tr>
                <td>{{vClaimsAll.comments}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="center-button-container" *ngIf="!isLoading">
        <button style="width: fit-content;" class="btn mat-raised-button" (click)=openAddNoteDialog()>Add New
          Note</button>&nbsp;
      </div>

      <div class="content">
        <div fxLayout="row wrap" fxLayoutGap="12px grid">
          <div class="content">
            <div fxLayout="row wrap" fxLayoutGap="12px grid">

              <div class="section-header" fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%">
                <mat-card class="mat-card-heading">
                  <h6>Notes</h6>
                </mat-card>
              </div>
            </div>
          </div>
        </div>

        <div class="mat-elevation-z8" style="padding-top: 12px ! important">
          <!-- Claim Notes Table -->
          <mat-table [dataSource]="dataSourceNotes" matSort matSortStart="asc">
            <ng-container matColumnDef="date">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Action Date</th>
              <td mat-cell *matCellDef="let notes">{{notes.date | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="addedBy">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Added By</th>
              <td mat-cell *matCellDef="let notes">{{functionsService.getDisplayName(notes.addedBy)}}</td>
            </ng-container>

            <ng-container matColumnDef="initiatedBy">
              <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Initiated By</th>
              <td mat-cell *matCellDef="let notes">{{notes.initiatedBy}}</td>
            </ng-container>

            <ng-container matColumnDef="notes">
              <th style="text-align: left;" mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
              <td mat-cell style="text-align: justify;" *matCellDef="let notes" [innerHTML]="notes.notes"></td>
            </ng-container>

            <ng-container matColumnDef="private">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Private</th>
              <td mat-cell *matCellDef="let notes">{{notes.private}}</td>
            </ng-container>

            <ng-container matColumnDef="followUpDate">
              <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Follow-Up Date</th>
              <td mat-cell *matCellDef="let notes">{{notes.followUpDate | date: 'dd/MM/yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="typeDescription">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Note Type</th>
              <td mat-cell *matCellDef="let notes">{{notes.typeDescription}}</td>
            </ng-container>

            <ng-container matColumnDef="subTypeDescription">
              <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Contact Type</th>
              <td mat-cell *matCellDef="let notes">{{notes.subTypeDescription}}</td>
            </ng-container>

            <ng-container matColumnDef="reasonDescription">
              <th class="w-8" mat-header-cell *matHeaderCellDef mat-sort-header>Reason</th>
              <td mat-cell *matCellDef="let notes">{{notes.reasonDescription}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedNotesColumns"></tr>
            <tr mat-row class="row-hover mat-row" [class.activeNoteRow]="activeNoteRow === row"
              (click)="openNoteDeailsDialog(row, row.notes)" *matRowDef="let row; columns: displayedNotesColumns;"
              [ngClass]="{'highlight-table-row': row.subTypeDescription == 'Portal'}"></tr>
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="9999">
                No notes found for this claim
              </td>
            </tr>
          </mat-table>
        </div>

        <div class="center-button-container" *ngIf="!isLoading">
          <button style="width: fit-content;" class="btn mat-raised-button" (click)=openAddNoteDialog()>Add New
            Note</button>&nbsp;
          <button class="btn mat-raised-button" (click)=goBack()>Back</button>
        </div>
      </div>