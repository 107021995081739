<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true">
  <div class="loading-container" *ngIf="isLoading">
    <div class="lds-grid">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>

  <div class="portal-header">
    <h5 class="header-text">All Open Claims</h5>
  </div>


  <!-- Filter Box -->
  <div class="search-box-container">
    <mat-form-field class="search-box-form-field">
      <mat-label>Search Claims by Keyword</mat-label>
      <input #search matInput type="text" (keyup)="onSearchKeyUp(search)" [value]="searchValue" autofocus>
      <button *ngIf="search" matSuffix mat-icon-button aria-label="Clear" (click)="onClearClicked(search)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div style="vertical-align: middle;padding-top:10px;">
      &nbsp;
      <button style="width: fit-content;height: fit-content; vertical-align: middle;" class="btn btn-sm btn-danger mat-raised-button"
        (click)="exporter.exportTable('xlsx',{fileName:'Mk3 Open Claims ' + functionsService.getCurrentTimestampFormatted()})">Export
        to Excel</button>
    </div>
  </div>

  <div class="main-table-container">
    <table mat-table matTableExporter [dataSource]="dataSource" matSort matSortStart="asc" #exporter="matTableExporter">
      <caption>Open Claims</caption>

      <ng-container matColumnDef="caseNo">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Case Number</th>
        <td mat-cell *matCellDef="let cases">{{cases.caseNo}}</td>
      </ng-container>

      <ng-container matColumnDef="refNo">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Mk3 Ref</th>
        <td mat-cell *matCellDef="let cases">{{cases.refNo}}</td>
      </ng-container>

      <ng-container matColumnDef="lastNameS">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Insured</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastNameS}}</td>
      </ng-container>

      <ng-container matColumnDef="postCode">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Postcode</th>
        <td mat-cell *matCellDef="let cases">{{cases.postCode}}</td>
      </ng-container>

      <ng-container matColumnDef="recD">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Date Received</th>
        <td mat-cell *matCellDef="let cases">{{cases.recD | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="lastInspDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Last Inspection</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.lastInspDate) == functionsService.getTime5555(); else elseValidLastInspDate">
            N/A
          </ng-container>
          <ng-template #elseValidLastInspDate>
            {{cases.lastInspDate | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="officeContact">
        <th class="w-2" mat-header-cell *matHeaderCellDef mat-sort-header>Office Contact</th>
        <td mat-cell *matCellDef="let cases">{{cases.officeContact}}</td>
      </ng-container>

      <ng-container matColumnDef="sentReport">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Inspection Report</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.sentReport) == functionsService.getTime5555(); else elseValidInspReportDate">
            N/A
          </ng-container>
          <ng-template #elseValidInspReportDate>
            {{cases.sentReport | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="lastContact">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Last Contact</th>
        <td mat-cell *matCellDef="let cases">{{cases.lastContact | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <ng-container matColumnDef="contactDays">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Days From Last Contact</th>
        <td mat-cell *matCellDef="let cases">{{cases.contactDays}}</td>
      </ng-container>

      <ng-container matColumnDef="holdContact">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Hold Contact</th>
        <td mat-cell *matCellDef="let cases">
          <mat-cell style="border: 0px;" *ngIf="cases.holdContact==-1">Yes</mat-cell>
          <mat-cell style="border: 0px;" *ngIf="cases.holdContact==0">No</mat-cell>
        </td>
      </ng-container>

      <ng-container matColumnDef="acceptance">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Claim Accepted</th>
        <td mat-cell *matCellDef="let cases">{{cases.acceptance}}</td>
      </ng-container>

      <ng-container matColumnDef="peril">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Peril</th>
        <td mat-cell *matCellDef="let cases">{{cases.peril}}</td>
      </ng-container>

      <ng-container matColumnDef="emergencyRepair">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Emergency Repair</th>
        <td mat-cell *matCellDef="let cases">
          <mat-cell style="border: 0px;" *ngIf="cases.emergencyRepair=='No'">No</mat-cell>
          <mat-cell style="border: 0px;" *ngIf="cases.emergencyRepair!='No'">Yes</mat-cell>
        </td>
      </ng-container>

      <ng-container matColumnDef="totalEstimate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Total Reserve</th>
        <td mat-cell *matCellDef="let cases">{{cases.totalEstimate|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="bldEstimate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Building Reserve</th>
        <td mat-cell *matCellDef="let cases">{{cases.bldEstimate|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="ctsEstimate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Contents Reserve</th>
        <td mat-cell *matCellDef="let cases">{{cases.ctsEstimate|currency:'USD':true:'1.0-3'}}</td>
      </ng-container>

      <ng-container matColumnDef="ecdEstimatedCompletionDate">
        <th class="w-5" mat-header-cell *matHeaderCellDef mat-sort-header>Expected Finalisation</th>
        <td mat-cell *matCellDef="let cases">
          <ng-container
            *ngIf="functionsService.getTimeValue(cases.ecdEstimatedCompletionDate) == functionsService.getTime5555(); else elseValidECD">
            N/A
          </ng-container>
          <ng-template #elseValidECD>
            {{cases.ecdEstimatedCompletionDate | date: 'dd/MM/yyyy'}}
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="statusModified">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Progress Status</th>
        <td mat-cell *matCellDef="let cases">{{cases.statusModified}}</td>
      </ng-container>

      <ng-container matColumnDef="reOpened">
        <th class="w-10" mat-header-cell *matHeaderCellDef mat-sort-header>Re-Opened</th>
        <td mat-cell *matCellDef="let cases">{{cases.reOpened | date: 'dd/MM/yyyy'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="'/case-details/'+ row.refNo"
        class="row-hover mat-row"></tr>
    </table>

    <mat-paginator  #paginator [pageSize]="12" [pageSizeOptions]="[12, 50, 100, dataSource.data.length]"
      showFirstLastButtons class="mat-paginator"></mat-paginator>
  </div>

  <div class="center-button-container" style="padding: 0px">
    <button mat-raised-button class="red-button" (click)=onClearClicked(search)>Clear</button>
  </div>
</div>


