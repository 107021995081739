<h1 mat-dialog-title style="color: white; background-color: var(--mk3red); text-align: center;">Add a new note to Claim {{data.caseNo}} {{data.lastNames}}</h1>
<mat-dialog-content text-align="middle" [formGroup]="form">
  <mat-form-field style="text-align: center;display: inline;">
    <textarea matInput width="800px" maxlength="2500" placeholder="Enter New Note"
      formControlName="noteDescription"></textarea>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions style="justify-content: center;">
  <button class="btn btn-danger" (click)="onSubmit()">Add Note</button>&nbsp;
  <button class="btn btn-danger" [mat-dialog-close]="data.note" (click)="close()">Cancel</button>
</mat-dialog-actions>
