import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { AccountService, AlertService } from '@app/_services';
import { Case } from "@app/_models/case";
import { RestService } from '@app/_services/rest.service';
import { FunctionsService } from '@app/_services/functions.service';
import { Observable } from 'rxjs';
import { Cat } from '@app/_models/cat';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-cat-claims',
  templateUrl: './cat-claims.component.html',
  styleUrls: ['./cat-claims.component.scss']
})
export class CatClaimsComponent implements OnInit {
  displayedColumns: string[] = ['refNo', 'caseNo', 'lastNameS', 'recD', 'inspDate', 'lastContact', 'contactDays', 'claimStatus', 'largeLoss',
    'state', 'postCode', 'cat', 'sentReport', 'xsDateRecD', 'ecdEstimatedCompletionDate', 'statusModified', 'bldEstimate',
    'ctsEstimate', 'bldAmtSettled', 'ctsAmtSettled', 'emergencyRepair', 'tempAccom', 'dateFinalised', 'holdContact'];
  dataSource!: MatTableDataSource<Case>;
  isLoading = true;
  search = '';
  catsList: any;
  allCats!: Observable<Cat[]>;
  latestCatId = 0;
  filterSelectObj: any = [];
  dateTimeNow = new Date();

  catFilter = new UntypedFormControl('');//FormControl('CAT212');
  stateFilter = new UntypedFormControl('');
  claimStatusFilter = new UntypedFormControl('');

  filterValues: any = {
    cat: '',
    state: '',
    claimStatus: ''
  }

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private restService: RestService, public accountService: AccountService,
    private alertService: AlertService, public functionsService: FunctionsService) {

    this.filterSelectObj = [
      {
        name: 'CAT',
        columnProp: 'cat',
        options: []
      }, {
        name: 'State',
        columnProp: 'state',
        options: []
      }
      , {
        name: 'Claim Status',
        columnProp: 'claimStatus',
        options: []
      }
    ]
  }

  ngOnInit() {
    try {
      //if (this.accountService.isLoggedIn()) {
        this.getRemoteData();
        this.fieldListener();
        return;
      // }
      // else
      //   this.isLoading = false;
    }
    catch (error) {
      //console.log('Error: :' + error.message);
      //this.alertService.error(error);
    }
  }

  // Get Unique values from columns to build the filter
  getFilterObject(fullObj: any[], key: string | number) {
    const uniqChk: any[] = [];
    fullObj.filter((obj: { [x: string]: any; }) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  //Fetch latest claims from the REST API
  getRemoteData() {
    try {
      this.isLoading = true;
      this.restService.getCATClaims().subscribe(
        cases => {
          this.dataSource = new MatTableDataSource(cases);
          this.matSort.sort(({ id: 'refNo', start: 'desc' }) as MatSortable);
          this.dataSource.sort = this.matSort;
          this.dataSource.paginator = this.paginator;
          this.filterSelectObj.filter((o: { options: any[]; columnProp: string | number; }) => {
            o.options = this.getFilterObject(this.dataSource.data, o.columnProp).sort();
          });
          this.dataSource.filterPredicate = this.createFilter();
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          return false;
        }
      );
      return true;
    }
    catch (error) {
      //this.alertService.error(error);
      return false;
    }
  }

  /** Gets the total number of claims */
  getTotalClaims() {
    return this.dataSource.data.length;
  }

  private fieldListener() {
    try {
      this.catFilter.valueChanges
        .subscribe(
          cat => {
            this.filterValues.cat = cat;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
      this.stateFilter.valueChanges
        .subscribe(
          state => {
            this.filterValues.state = state;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
      this.claimStatusFilter.valueChanges
        .subscribe(
          claimStatus => {
            this.filterValues.claimStatus = claimStatus;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
    }
    catch (error) {
      //this.alertService.error(error);
    }
  }

  clearFilters() {
    try {
      this.catFilter.setValue('');
      this.stateFilter.setValue('');
      this.claimStatusFilter.setValue('');
    }
    catch (error) {
      //this.alertService.error(error);
    }
  }

  private createFilter(): (data: Case, filter: string) => boolean {
    let filterFunction = function (this: any, data: { cat: string | any[]; state: string | any[]; claimStatus: string | any[]; }, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.cat.indexOf(searchTerms.cat) !== -1
        && data.state.indexOf(searchTerms.state) !== -1
        && data.claimStatus.indexOf(searchTerms.claimStatus) !== -1;
    }
    return filterFunction;
  }

  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }
}

