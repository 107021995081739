<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true">
  <div class="portal-header">
    <h5 class="header-text">Mk3 Portal</h5>
  </div>

  <div class="home-container">
    <div class="p-4">
      <div class="container">
        <h4>Hello {{accountService.accountName}}!</h4>
        <p style="color: #004a93; font-weight: 600;">You're logged into the Mk3 Portal for Allianz Australia General
          Insurance Limited</p>
        <p></p>
        <p></p>
        <button (click)=openSearchDialog(false) class="btn btn-sm mat-raised-button btn-danger">Search by
          Allianz Claim Number</button>&nbsp;
        <button (click)=openSearchDialog(true) class="btn btn-sm mat-raised-button btn-danger">Search by Mk3
          Reference Number</button>&nbsp;
        <button [routerLink]="'./cases'" class="btn btn-sm btn-danger mat-raised-button">Search by
          Keyword</button>&nbsp;
        <button [routerLink]="" (click)="this.accountService.logout()"
          class="btn btn-sm btn-danger mat-raised-button">{{this.accountService.getName()}}&nbsp;&nbsp;Sign Out</button>
        <!-- <button [routerLink]="'./reports'" class="btn btn-sm btn-danger mat-raised-button" *ngIf="user.roleID > 0">View Reports</button> -->
        <!-- <button [routerLink]="'./reports'" class="btn btn-sm btn-danger mat-raised-button">View Reports</button>
        &nbsp;&nbsp; &nbsp;&nbsp; -->
        <!-- <button [routerLink]="'./users'" class="btn btn-sm btn-danger mat-raised-button" *ngIf="user.roleID === 2">Manage Users</button> -->
        <!-- <button [routerLink]="'./users'" class="btn btn-sm btn-danger mat-raised-button">Manage Users</button> -->
      </div>
    </div>

  </div>
</div>
