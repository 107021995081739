<div class="app-container" *ngIf="accountService.isUserLoggedIn() === true">

    <div class="portal-header">
        <h5 class="header-text">Reports</h5>
      </div>

      <div class="home-container">
<div class="p-4">
    <div class="container" >
        <button [routerLink]="'./claim-received-summary'" class="btn btn-sm btn-danger mat-raised-button"  *ngIf="user.roleID > 0">Claims Summary</button> &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./all-open-claims'" class="btn btn-sm btn-danger mat-raised-button"  *ngIf="user.roleID > 0">All Open Claims</button> &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./cat-claims'" class="btn btn-sm btn-danger mat-raised-button"  *ngIf="user.roleID > 0">CAT Claims</button> &nbsp;&nbsp; &nbsp;&nbsp;
        <button [routerLink]="'./e2e-eol'" class="btn btn-sm btn-danger mat-raised-button"  *ngIf="user.roleID > 0">End To End Claims</button> &nbsp;&nbsp; &nbsp;&nbsp;
    </div>
</div>
</div>