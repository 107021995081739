import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { AccountService } from '@app/_services';
import { Case } from "@app/_models/case";
import { RestService } from '@app/_services/rest.service';
import { FunctionsService } from '@app/_services/functions.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-e2e-eol',
  templateUrl: './e2e-eol.component.html',
  styleUrls: ['./e2e-eol.component.scss']
})
export class E2eEolComponent implements OnInit {
  displayedColumns: string[] = ['refNo', 'caseNo', 'lastNameS', 'recD', 'inspDate', 'lastContact', 'contactDays', 'claimStatus', 'largeLoss',
    'state', 'postCode', 'sentReport', 'xsDateRecD', 'ecdEstimatedCompletionDate', 'statusModified', 'bldEstimate',
    'ctsEstimate', 'bldAmtSettled', 'ctsAmtSettled', 'emergencyRepair', 'tempAccom', 'dateFinalised', 'holdContact'];
  dataSource!: MatTableDataSource<Case>;
  isLoading = true;
  search = '';
  filterSelectObj: any = [];

  stateFilter = new UntypedFormControl('');
  claimStatusFilter = new UntypedFormControl('');

  filterValues: any = {
    state: '',
    claimStatus: ''
  }

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private restService: RestService, public accountService: AccountService,
    public functionsService: FunctionsService) {

    this.filterSelectObj = [
      {
        name: 'State',
        columnProp: 'state',
        options: []
      }
      , {
        name: 'Claim Status',
        columnProp: 'claimStatus',
        options: []
      }
    ]
  }

  ngOnInit() {
    //this.isLoading = true;
    // if (this.accountService.isLoggedIn()) {
      this.getRemoteData();
      this.fieldListener();
      return;
    // }
    // else
    //   this.isLoading = false;
  }

  // Get Unique values from columns to build the filter
  getFilterObject(fullObj: any[], key: string | number) {
    const uniqChk: any[] = [];
    fullObj.filter((obj: { [x: string]: any; }) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  //Fetch E2E claims from the REST API
  getRemoteData() {
    try {
      this.isLoading = true;
      this.restService.getE2eEoLClaims().subscribe(
        cases => {
          this.dataSource = new MatTableDataSource(cases);
          this.matSort.sort(({ id: 'refNo', start: 'desc' }) as MatSortable);
          this.dataSource.sort = this.matSort;
          this.dataSource.paginator = this.paginator;
          this.filterSelectObj.filter((o: { options: any[]; columnProp: string | number; }) => {
            o.options = this.getFilterObject(this.dataSource.data, o.columnProp).sort();
          });
          this.dataSource.filterPredicate = this.createFilter();
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          return false;
        }
      );
      return true;
    }
    catch (error) {
      //this.alertService.error(error);
      return false;
    }
  }

  //Reload the data from the database
  refreshData() {
    this.dataSource.data = [...this.dataSource.data];
    this.paginator._changePageSize(12);
    this.matSort.sort(({ id: 'refNo', start: 'desc' }) as MatSortable);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Filter claims by keyword
  onSearchKeyUp(search: { value: any; }) {
    var currentFilter = search.value;
    this.dataSource.filter = currentFilter;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Clear filter claims by keyword
  onClearClicked(search: { value: string; }) {
    this.dataSource.filter = "";
    search.value = "";

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Return the claim status - Active or Finalised
  /*getClaimStatus(bldCompletion: Date, ctsCompletion: Date) {
    if (bldCompletion == null || ctsCompletion == null) {
      return "Open";
    }
    else {
      return "Finalised";
    }
  }*/

  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }

  /** Gets the total cost of all transactions. */
  getTotalClaims() {
    return this.dataSource.data.length;
  }


  private fieldListener() {
    try {
      this.stateFilter.valueChanges
        .subscribe(
          state => {
            this.filterValues.state = state;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
      this.claimStatusFilter.valueChanges
        .subscribe(
          claimStatus => {
            this.filterValues.claimStatus = claimStatus;
            this.dataSource.filter = JSON.stringify(this.filterValues);
          }
        )
    }
    catch (error) {
      //this.alertService.error(error);
    }
  }

  clearFilters() {
    try {
      this.stateFilter.setValue('');
      this.claimStatusFilter.setValue('');
    }
    catch (error) {
      //this.alertService.error(error);
    }
  }

  private createFilter(): (data: Case, filter: string) => boolean {
    let filterFunction = function (this: any, data: { state: string | any[]; claimStatus: string | any[]; }, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      return data.state.indexOf(searchTerms.state) !== -1
        && data.claimStatus.indexOf(searchTerms.claimStatus) !== -1;
    }
    return filterFunction;
  }
}
