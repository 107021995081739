// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //authApiUrl: 'http://localhost:59649/api',//Local host Auth Web API for testing
  //authApiUrl: 'http://mk3-web:8010/api',//IIS Auth Web API
  wgilAPIBaseUrl: 'https://wgil-api-management.azure-api.net/v1/api/',
  authApiUrl: 'https://wgil-api-management.azure-api.net/api',//Azure Auth Web API
  //claimsLatestApiUrl: 'http://mk3-web:8009/api/WgiCasesLatests'//IIS Web API for Latest Cases
  claimsLatestApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/WgicasesLatests',//Azure Web API for Latest Cases
  claimsAllApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/WgicasesAlls',//Azure Web API for Latest Cases
  catReportApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/Cats',//Azure Web API for CAT Reports
  e2eReportApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/E2eEol',//Azure Web API for E2E Reports
  catsListApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/CatsLists',//Azure Web API for the list of CATs
  claimNotesListApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/WgilClaimNotesLists',//Azure Web API for the list of Claim Notes
  claimContactApiUrl: 'https://wgil-api-management.azure-api.net/v1/api/ClaimContacts/ContactsList', //Azure Web API for the list of Claim Contacts'
  mk3AzureApiUrl: 'https://wgil-api-management.azure-api.net/azure/api'
  //mk3AzureApiUrl: 'https://localhost:7093/api'//https://localhost:7093/api/Files/59200%20Test
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
