import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatTableDataSource } from '@angular/material/table';
import { Case } from '@app/_models/case';
import { AccountService, AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';

@Component({
  selector: 'app-all-open-claims',
  templateUrl: './all-open-claims.component.html',
  styleUrls: ['./all-open-claims.component.scss']
})
export class AllOpenClaimsComponent implements OnInit {
  displayedColumns: string[] = ['caseNo', 'refNo', 'lastNameS', 'postCode', 'recD', 'lastInspDate', 'officeContact', 'sentReport', 'lastContact', 'contactDays',
    'holdContact', 'acceptance', 'peril', 'emergencyRepair', 'totalEstimate', 'bldEstimate', 'ctsEstimate', 'ecdEstimatedCompletionDate', 'statusModified', 'reOpened'];
  dataSource!: MatTableDataSource<Case>;
  isLoading = true;
  search = '';
  searchValue = '';

  @ViewChild(MatSort, { static: true }) matSort!: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private restService: RestService, public accountService: AccountService,
    private alertService: AlertService, public functionsService: FunctionsService) {
  }

  ngOnInit(): void {
    //if (this.accountService.isLoggedIn()) {
      this.loadData();
      return;
    // }
    // else
    //   this.isLoading = false;
  }

  //Fetch latest claims from the REST API
  loadData() {
    this.isLoading = true;
    this.restService.getAllOpenCases().subscribe(
      cases => {
        this.dataSource = new MatTableDataSource(cases);
        this.matSort.sort(({ id: 'refNo', start: 'desc' }) as MatSortable);
        this.dataSource.sort = this.matSort;
        this.dataSource.paginator = this.paginator;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
      }
    );
  }

  //Reload the data from the database
  refreshData() {
    this.dataSource.data = [...this.dataSource.data];
    this.search = "";
    this.searchValue = '';
    this.paginator._changePageSize(12);
    this.matSort.sort(({ id: 'refNo', start: 'desc' }) as MatSortable);

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Filter claims by keyword
  onSearchKeyUp(search: { value: any; }) {
    var currentFilter = search.value;
    this.dataSource.filter = currentFilter;

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Clear filter claims by keyword
  onClearClicked(search: { value: string; }) {
    search.value = "";
    this.searchValue = '';
    this.search = '';

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Return the claim status - Active or Finalised
  /*getClaimStatus(bldCompletion: Date, ctsCompletion: Date) {
    if (bldCompletion == null || ctsCompletion == null) {
      return "Open";
    }
    else {
      return "Finalised";
    }
  }*/

  getClaimStatus(statusClaimTypeID: Number) {
    if (statusClaimTypeID == 1 || statusClaimTypeID == 3) {
      return 'Open';
    } else {
      return 'Finalised';
    }
  }
}
