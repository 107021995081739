import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Peril } from '@app/_models/peril';
import { ReceivedSummary } from '@app/_models/received-summary';
import { AccountService, AlertService } from '@app/_services';
import { FunctionsService } from '@app/_services/functions.service';
import { RestService } from '@app/_services/rest.service';

import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/en-gb';

@Component({
  selector: 'app-claim-received-summary',
  templateUrl: './claim-received-summary.component.html',
  styleUrls: ['./claim-received-summary.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class ClaimReceivedSummaryComponent implements OnInit {
  isLoading!: boolean;
  summary!: ReceivedSummary;
  startDate?: any = null;//new Date(this.addDays(-7));
  endDate?: any = null;//new Date();
  dateRange: UntypedFormGroup;
  minDate!: Date;
  maxDate!: Date;
  displayedPerilsColumns: string[] = ['peril', 'count'];
  dataSourcePerils!: MatTableDataSource<Peril>;

  @ViewChild(MatTable)
  //@ViewChild('picker') picker: any
  table!: MatTable<any>;

  constructor(private restService: RestService, public accountService: AccountService, private alertService: AlertService,
    public functionsService: FunctionsService, private dateAdapter: DateAdapter<any>) {
    this.isLoading = true;
    const today = new Date();
    this.minDate = new Date(today.getFullYear() - 2, today.getMonth(), today.getDate());
    this.maxDate = new Date();
    this.dateAdapter.setLocale('en-GB');

    this.startDate = new Date(this.addDays(-7));
    this.endDate = new Date();

    this.dateRange = new UntypedFormGroup({
      startDate: new UntypedFormControl(this.startDate, [Validators.required]),//, this.functionsService.datePickerValidator()
      endDate: new UntypedFormControl(this.endDate, [Validators.required])//, this.functionsService.datePickerValidator()
    });
  }

  ngOnInit(): void {
    //this.picker.open();
    try {
      this.getData();
    }
    catch (error) {
      //console.log('Error: :' + error.message);
      //this.alertService.error(error);
    }
  }

  getData() {
    this.isLoading = true;
    this.alertService.clear();
    //if (this.accountService.isLoggedIn()) {
      if (this.startDate != null && this.endDate != null) {
        //Get claim counts
        this.restService.getClaimReceivedSummary(this.startDate, this.endDate).subscribe(
          (data: ReceivedSummary[]) => {
            this.summary = data[0];
            this.isLoading = false;
            this.restService.getPerilsSummary(this.startDate, this.endDate).subscribe(
              perils => {
                this.dataSourcePerils = new MatTableDataSource(perils);
              },
              error => {
                //this.resetData();
                this.isLoading = false;
                this.alertService.error(error);
              });
              //this.endDate = null;
          },
          error => {
            //this.resetData();
            this.isLoading = false;
            this.alertService.error(error);
          });
      }
    // }
    // else
    //   this.isLoading = false;
  }

  addDays(days: number): Date {
    var newDate = new Date();
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  startDateChangeEvent(event: { value: Date; }) {
    // if (event.value != null) {
    this.startDate = event.value;
    this.endDate = null;

    if (this.dateRange.controls['endDate'].touched != true || this.endDate == null || this.endDate < this.startDate  || !this.functionsService.datePickerValidator()) {
      this.resetData();
    }
    else
    {
      this.getData();
    }
  }

  endDateChangeEvent(event: { value: Date; }) {
    if (event.value != null) {
      this.endDate = event.value;
    //  this.resetData();
    }
    //else
    //{
    if (event.value == null || this.endDate < this.minDate || this.endDate > this.maxDate || !this.functionsService.datePickerValidator()) {
      this.resetData();
    }
    else {
      this.endDate = event.value
      this.getData();
    }

    //}
  }

  resetData() {
    this.summary = {
      receivedCount: 0,
      contact24: 0,
      contact48: 0,
      inspectedCount: 0,
      finalisedReceivedInPeriodCount: 0,
      finalisedCountAll: 0,
      acceptedFinalised: 0,
      partialFinalised: 0,
      denialRecommendedFinalised: 0,
      cancelledCount: 0,
      cycleTime: 0,
      cashSettled: 0,
      completedByMk3Partners: 0,
      withdrawnFinalised: 0,
      otherFinalised: 0,
      totalOpen: 0,
      totalYetToBeInspected: 0,
      total30DaysOrLess: 0,
      total31To60DaysOld: 0,
      total61To90DaysOld: 0,
      totalOver90DaysOld: 0,
      totalReOpened: 0
    };
    this.restService.getPerilsSummary(new Date('01/01/0001'), new Date('01/01/0001')).subscribe(
      perils => {
        this.dataSourcePerils = new MatTableDataSource(perils);
      });
    //this.dataSourcePerils.data = null;
  }
}
