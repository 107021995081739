//The user model is a small class that defines the properties of a user

export class User {
    id: string = '';
    username: string = '';
    password: string = '';
    firstName: string = '';
    lastName: string = '';
    token: string = '';
    roleID: number = 0;
}