import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[autoFocus]'
})
export class AutoFocusDirective implements OnInit {
    @Input('autoFocus') isFocused: boolean = false;
    constructor(private hostElement: ElementRef) {
    }

    ngOnInit() {
        if (this.isFocused) {
            this.hostElement.nativeElement.focus();
        }
    }
}