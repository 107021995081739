//The Alert model defines the properties of each alert object

export class Alert {
    id: string = '';
    type!: AlertType;
    message: string = '';
    autoClose?: boolean;
    keepAfterRouteChange?: boolean;
    fade?: boolean;

    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}

//AlertType enum defines the types of alerts allowed in the application
export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}