import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { b2cPolicies } from '@app/_services/auth-config';
import { AlertService } from '@app/_services';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, AuthError, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

//The account service handles communication between the Angular app and the backend api for everything related to accounts
//It contains methods for the login, logout and registration, as well as and standard CRUD methods for retrieving and modifying user data.

//On successful login the returned user is stored in browser local storage to keep the user logged in between page refreshes and browser sessions
//if prefer not to use local storage you can simply remove it from the account service and the application will continue to work correctly
//except for staying logged in between page refreshes.

//The user property exposes an RxJS observable (Observable<User>) so any component can subscribe to be notified when a user logs in, logs out or updates their profile
//The notification is triggered by the call to this.userSubject.next() from each of those methods
@Injectable({ providedIn: 'root' })
export class AccountService implements OnInit, OnDestroy {
    private readonly _destroying$ = new Subject<void>();
    accountName: string = '';

    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private route: ActivatedRoute,
        public dialog: MatDialog
    ) {
    }

    ngOnInit(): void {
        this.startup();
    }

    startup() {
        /**
      * You can subscribe to MSAL events as shown below. For more info,
      * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
      */
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAndSetActiveAccount(null);
                //this.getClaims(this.msalService.instance.getActiveAccount()?.idTokenClaims)
            });

        this.route.paramMap
            .subscribe(params => {
                //console.log(params);
            }
            );

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                //console.log(result);
                if (result.error instanceof AuthError) {
                    // Check for forgot password error
                    // Learn more about AAD error codes at https://docs.microsoft.com/azure/active-directory/develop/reference-aadsts-error-codes
                    if (result.error.message.includes('AADB2C90118')) {
                        sessionStorage.setItem('changePassword', 'true');
                        // login request with reset authority
                        let resetPasswordFlowRequest = {
                            scopes: ["openid"],
                            authority: b2cPolicies.authorities.forgotPassword.authority,//this.policies.authorities.forgotPassword.authority,
                        }
                        this.login(resetPasswordFlowRequest);
                    }
                }
            });
        this.accountName = this.msalService.instance.getActiveAccount()?.name!;
    }

    checkAndSetActiveAccount(account: AccountInfo | null) {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */

        let activeAccount: AccountInfo | null;

        if (!account)
            activeAccount = account;
        else
            activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            this.msalService.instance.setActiveAccount(accounts[0]);
            this.setUserLoggedIn(true);
        }
    }

    getClaims(claims: any) {
        //this.dataSource = [
    }

    login(userFlowRequest?: RedirectRequest | PopupRequest) {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.checkAndSetActiveAccount(response.account);
                    });
            } else {
                this.msalService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.checkAndSetActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                var changePassword = sessionStorage.getItem("changePassword");
                if (changePassword == null)
                    this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
                else {
                    sessionStorage.removeItem("changePassword");
                    this.handlePasswordReset();
                }
            } else {
                this.msalService.loginRedirect(userFlowRequest);
            }
        }
    }

    forgotPassword() {
        let forgotPasswordFlowRequest = {
            scopes: ["openid"],
            authority: b2cPolicies.authorities.forgotPassword.authority,
        };
        this.login(forgotPasswordFlowRequest);
    }

    private handlePasswordReset() {
        // we simply redirect the user to the reset password page.
        window.location.href = b2cPolicies.authorities.forgotPassword.authority;
    }

    editProfile() {
        let editProfileFlowRequest = {
            scopes: ["openid"],
            //authority: b2cPolicies.authorities.editProfile.authority,
        };
        this.login(editProfileFlowRequest);
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    logout() {
        const currentAccount = this.msalService.instance.getActiveAccount();
        this.msalService.logoutRedirect({
            account: currentAccount,
            postLogoutRedirectUri: '/'
        });
        this.setUserLoggedIn(false);
    }

    setUserLoggedIn(userLoggedIn: boolean) {
        sessionStorage.setItem('userLoggedIn', userLoggedIn.toString());
        if (userLoggedIn) {
            this.accountName = this.msalService.instance.getActiveAccount()?.name!;
        }
    }

    isUserLoggedIn(): boolean {
        let retVal: boolean = sessionStorage.getItem('userLoggedIn') == 'true' ? true : false;
        return retVal;
    }

    public getName(): string {
        return this.accountName;
    }

    //The subscriptionKey getter allows other components to easily get the subscription key
    public get subscriptionKey(): string {
        return '?subscription-key=79c1934d47f54b818f72e00b76118d6a';
    }

    public get isAllianzSubscriptionKey(): string {
        return '?isAllianz=true&subscription-key=79c1934d47f54b818f72e00b76118d6a';
    }
    public get clientFriendlyName(): string {
        //return 'JvRcaTkbYXlirKZj1wgRaQ==';//Mk3
        return 'JqfmgyyDRZKKTRnmHAuuSw==';//Allianz
        //return 'PxGK750wGRMkeO/tZwmzZA==';//Proplab
    }
}