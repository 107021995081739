<div class="loading-container" *ngIf="isLoading">
  <div class="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>


<div>
<table mat-table [dataSource]="files" class="mat-elevation-z8 table-striped">
  <ng-container matColumnDef="fileName">
    <th mat-header-cell *matHeaderCellDef>File Name</th>
    <td mat-cell *matCellDef="let files">{{files}}</td>
  </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef>View</th>
    <td mat-mdc-cell *matCellDef="let files"><button (click)="downloadFile(files, true)"
        class="btn btn-sm mat-raised-button btn-danger">View</button></td>
  </ng-container>

  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef>Download</th>
    <td mat-cell *matCellDef="let files"><button  (click)="downloadFile(files, false)"
        class="btn btn-sm mat-raised-button btn-danger">Download</button></td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="downloadFile(row.fileName, undefined)"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="9999">
      <div>
        <b>No files available</b>
      </div>
    </td>
  </tr>
</table>
</div>
